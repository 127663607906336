<template>
    <v-container>
        <author-request-pool-list
            :requests="requests"
            :loading="loading"
            :updating="updating"
            class="px-0"
            v-on="$listeners"
        />

        <assignment-comments
            :assignment-id="showCommentsForAssignmentId"
            @close="hideComments"
            @update="update"
        />
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AssignmentComments } from '@/components/Authoring/AssignmentComments';
import { AuthorRequestPoolList } from '@/components/Authoring/AuthorRequestPoolList';

import type { AuthorRequest } from '@/types/AuthorRequest';

const AuthorRequestPoolSectionProps = Vue.extend({
    name: 'AuthorRequestPoolSection',
    props: {
        requests: {
            type: Array as PropType<AuthorRequest[]>,
            default() {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AssignmentComments,
        AuthorRequestPoolList
    }
})
export default class AuthorRequestPoolSection extends AuthorRequestPoolSectionProps {
    showCommentsForAssignmentId = 0;

    showComments(id: number) {
        this.showCommentsForAssignmentId = id;
    }

    hideComments() {
        this.showCommentsForAssignmentId = 0;
    }

    update() {
        this.$emit('update', true);
    }
}
</script>
