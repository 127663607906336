import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"transition":"slide-y-transition","close-on-click":true,"close-on-content-click":true,"offset-y":true,"left":""},on:{"input":_vm.open},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","plain":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("ellipsis-v")])],1)]}}])},[_c(VList,_vm._l((_vm.actions),function(action,key){return _c(VListItem,{key:'authoring--action-pool-' + key,attrs:{"href":_vm.getHref(action),"to":_vm.getTo(action)},on:{"click":function($event){return _vm.onAction(action)}}},[_c(VListItemTitle,{class:{
                    'pl-0 ml-n1': action.iconText
                }},[(action.icon)?_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(action.icon)+" ")]):(action.iconText)?_c(VChip,{staticClass:"pa-1 mr-1 justify-center chip-as-icon",attrs:{"color":"secondary","text-color":"white","small":"","link":""}},[_vm._v(" "+_vm._s(action.iconText)+" ")]):_vm._e(),_vm._v(" "+_vm._s(action.name)+" ")],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }