<template>
    <v-row v-if="loading" class="ma-0">
        <v-col cols="1" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="70%" height="20" />
        </v-col>
        <v-col v-if="hideAuthor" cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="50%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="50%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="40%" height="20" />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="30%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="70%" height="20" />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="60%" height="20" />
        </v-col>
        <v-col v-if="!hideAuthor" cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="40%" height="20" />
        </v-col>
        <v-col cols="1" />
    </v-row>
    <v-row v-else class="caption text-uppercase ma-0">
        <v-col cols="1" class="pl-2 pr-0">Status</v-col>
        <v-col v-if="hideAuthor" cols="2" class="pl-2 pr-0">Headline</v-col>
        <v-col cols="2" class="pl-2 pr-0">Category</v-col>
        <v-col cols="2" class="pl-2 pr-0">
            <a-sortable-column column="company">Company</a-sortable-column>
        </v-col>
        <v-col cols="1" class="pl-2 pr-0">Type</v-col>
        <v-col cols="2" class="pl-2 pr-0">
            <a-sortable-column column="priority" is-default>
                Priority
            </a-sortable-column>
        </v-col>
        <v-col cols="1" class="pl-2 pr-0">
            <a-sortable-column column="queue">Days Queued</a-sortable-column>
        </v-col>
        <v-col v-if="!hideAuthor" cols="2" class="pl-2 pr-0">Author</v-col>
        <v-col cols="1" />
    </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { ASortableColumn } from '@/components/AList';

const AuthorRequestPoolListHeaderProps = Vue.extend({
    name: 'AuthorRequestPoolListHeader',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        ASortableColumn
    }
})
export default class AuthorRequestPoolListHeader extends AuthorRequestPoolListHeaderProps {
    @InjectReactive({
        from: 'hideAuthor',
        default() {
            return false;
        }
    })
    hideAuthor!: boolean;
}
</script>
