import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VRow,{staticClass:"a-list-row ma-0 mb-2 white pt-1 pb-1"},[_c(VCol,{staticClass:"pl-3 pr-0 pt-3 pb-3 ma-auto",attrs:{"cols":"1"}},[_c(VSkeletonLoader,{attrs:{"type":"heading"}})],1),(_vm.hideAuthor)?_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(45, 75) + '%'}})],1):_vm._e(),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(45, 75) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(35, 85) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"1"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(20, 35) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(30, 45) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"1"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(30, 45) + '%'}})],1),(!_vm.hideAuthor)?_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(30, 45) + '%'}})],1):_vm._e(),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 py-0 ma-auto",attrs:{"cols":"1"}})],1):_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VRow,{staticClass:"a-list-row ma-0 mb-2 align-center",class:{
            white: !hover && !_vm.isActive,
            'concrete-solid': hover || _vm.isActive,
            'elevation-2': _vm.hasAction && (hover || _vm.isActive),
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
            'with-action': _vm.hasAction
        },on:{"click":_vm.onAction}},[_c(VCol,{staticClass:"position--relative",attrs:{"cols":"1"}},[_c('status-chip',{attrs:{"status":_vm.isRejected ? 'Rejected' : _vm.request.status_string,"tooltip":_vm.blockedReason}}),(_vm.hasNewComments)?_c(VTooltip,{attrs:{"bottom":"","z-index":"10"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"new-comments--circle new-comments--circle-list"},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("New comments in the last 48 hours")])]):_vm._e()],1),(_vm.hideAuthor)?_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",class:{
                'cell-disabled': !_vm.hasHeadLine
            },attrs:{"cols":"2"}},[_c('text-truncated',{attrs:{"text":_vm.headline}})],1):_vm._e(),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",class:{
                'cell-disabled': !_vm.hasCategory
            },attrs:{"cols":"2"}},[_c('text-truncated',{attrs:{"text":_vm.category}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",class:{
                'cell-disabled': !_vm.hasCompanyName
            },attrs:{"cols":"2"}},[_c('text-truncated',{attrs:{"text":_vm.companyName}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"1"}},[_c('author-request-type-chip',{attrs:{"request":_vm.request,"assignment":_vm.request.latest_assignment}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.priorityLevel)+" ")]),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.daysInQueue)+" ")]),(!_vm.hideAuthor)?_c(VCol,{staticClass:"pl-2 pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.authorName)+" ")]):_vm._e(),_c(VCol,{staticClass:"pl-2 pr-0 py-0 ma-auto",attrs:{"cols":"1","align":"right"}},[_c('author-request-row-actions',{attrs:{"request":_vm.request},on:{"open":_vm.markRowActive,"redirect":_vm.redirect,"show-comments":_vm.showComments}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }